import { createClient } from "@supabase/supabase-js";
import axios from "axios";

const api = axios.create({
  baseURL: "https://vms-api.siamai.workers.dev/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 3000,
});

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getData = async (id) => {
  const { data } = await supabase.from("contact").select("*").eq("contact_id", id);
  return await data;
};
