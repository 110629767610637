import React from "react";
import { PhoneOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Avatar, Card, Image } from "antd";
import { getData } from "./services/api";
const { Meta } = Card;

const queryParameters = new URLSearchParams(window.location.search);
const id = queryParameters.get("id");

const App = () => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    getData(id).then((data) => {
      setData(data[0]);
    });
  }, [id]);

  if (!data) {
    return <div>Loading...</div>;
  }

  const handleSaveContact = () => {
    const vcard = `BEGIN:VCARD
VERSION:3.0
N:${data.name}
FN:${data.name}
ORG:SIAM A.I. Co., Ltd.
TITLE:${data.position}
PHOTO;TYPE=JPEG;ENCODING=b:${data.preview}
TEL:${data.tel}
EMAIL:${data.email}
URL:https://siamai.tech
ADR:101 True Digital Park, Pegasus Building, 5th Floor, Unit 522, Sukhumvit road, Bangchak, Prakanong, Bangkok 10260
END:VCARD`;
    const encodedUri = encodeURI(vcard);
    window.open("data:text/x-vcard;charset=utf-8," + encodedUri, "_blank");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Card
        style={{
          width: "100vw",
        }}
        cover={<img alt="example" src="banner.jpg" />}
        actions={[
          <Button
            key="call"
            type="primary"
            style={{ backgroundColor: "#493AA2" }}
            shape="round"
            icon={<PhoneOutlined />}
            size="large"
            onClick={() => {
              window.location.href = `tel:${data.tel}`;
            }}
          >
            Call
          </Button>,
          <Button
            key="line"
            type="primary"
            style={{ backgroundColor: "#00B900" }}
            shape="round"
            icon={
              <img
                width={20}
                src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png"
                alt="line"
              />
            }
            size="large"
            onClick={() => {
              window.location.href = data.line;
            }}
          >
            Line
          </Button>,
        ]}
      >
        <Meta
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: -120,
          }}
          avatar={
            <Avatar size={200} src={"data:image/jpeg;base64," + data.preview} />
          }
          title={<h3>{data.name}</h3>}
          description={
            <>
              <h3
                style={{
                  marginTop: -30,
                  color: "black",
                }}
              >
                {data.position}
              </h3>
              <h4
                style={{
                  marginTop: -20,
                }}
              >
                SIAM A.I. Co., Ltd.
              </h4>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="primary"
                  style={{ backgroundColor: "#11091E", height: 50 }}
                  shape="round"
                  icon={<SaveOutlined />}
                  size="large"
                  onClick={handleSaveContact}
                >
                  Save Contact
                </Button>
              </div>
            </>
          }
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Image
            style={{
              marginTop: 20,
              borderRadius: 10,
            }}
            width={200}
            src="map.jpg"
          />
        </div>
      </Card>
    </div>
  );
};

export default App;
